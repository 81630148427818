import GtrSuper from "@/modules/common/components/mixins/gtr-super.mixin";
import { Component } from "vue-property-decorator";
import Container from "typedi";
import ErrorHandlerService from "@/modules/common/services/error-handler.service";

@Component({
    name: 'GtrIdpReturn',
})
export default class GtrIdpReturnView extends GtrSuper {
    data() {
        return {
            error: null as null | string
        }
    }
    async mounted() {
        try {
            const payload = {
                event_identifier: this.$route.params.event_identifier,
                data: {url: window.location.href}
            }
            const result = await this.$store.dispatch('register/idpReturnData', payload, {root: true})
            if (result.data?.[0]?.total === 0) {
                this.$data.error = 'This booth currently has 0 badges allotted.'
                throw 'This booth currently has 0 badges allotted.'
            }
            localStorage.setItem("idp_raw", JSON.stringify(result.data))
            this.$router.push('/idp-choose');
        } catch (error) {
            if (error.response?.status == 404) {
                this.$data.error = 'This contact was not found.'
            }
            Container.get(ErrorHandlerService).error(error)
        }
    }

}
